@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.gnp-background {
  background-color: #FF6C0E;
}

.w-45a {
  width: 44%;
}

.loader-10 {
  position: relative;
  -webkit-animation: loader-10-1 3s infinite linear;
  animation: loader-10-1 3s infinite linear;
}

.loader-10:before,
.loader-10:after {
  content: "";
  width: 0;
  height: 0;
  border: 0.5em solid #3E3CDF;
  display: block;
  position: absolute;
  border-radius: 100%;
  -webkit-animation: loader-10-2 3s infinite ease-in-out;
  animation: loader-10-2 3s infinite ease-in-out;
}

.loader-10:before {
  top: 0;
  left: 55%;
}

.loader-10:after {
  bottom: 0;
  right: 55%;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.ant-btn.ant-btn-primary {
  background-color: #3E3CDF;
  border-color: #3E3CDF;
  color: white;

}

.ant-btn.ant-btn-primary:hover {
  background: white !important;
  color: #3E3CDF !important;
}

.ant-upload.ant-upload-select {
  width: 100%;
}

@-webkit-keyframes loader-10-1 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-10-1 {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-10-2 {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(5);
  }
}

@keyframes loader-10-2 {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(5);
  }
}

.border-radius-5 {
  border-radius: 0 0.5rem 0.5rem 0;
}

.border-radius-5-l {
  border-radius: 0.5rem 0 0 0.5rem;
}

.color-texto-web {
  color: #7a7a7a;
}

@media screen and (max-width: 700px) {
  .border-radius-5 {
    border-radius: 0 0 0.5rem 0.5rem;
  }

  .border-radius-5-l {
    border-radius: 0.5rem 0.5rem 0 0;
  }
}

.slider-voestalpine .slick-next::before {
  color: #0082B4
}

.slider-voestalpine .slick-prev::before {
  color: #0082B4
}

/* .image-voestalpine .slick-center img {
  transform: scale(1.2);
} */

.custom-container {
  width: 80%;
  /* 4/5 en pantallas pequeñas */
  max-width: 83.33%;
  /* 10/12 en pantallas medianas */
  margin-left: auto;
  margin-right: auto;
  height: auto;
}

/* Media query para pantallas medianas */
@media (min-width: 768px) {
  .custom-container {
    width: 83.33%;
    /* 10/12 en pantallas medianas */
  }
}

/* crea una clase de letra con los archivos tff que tengas en la de assets/voestalpine */

@font-face {
  font-family: 'voestalpine-ligth';
  src: url('../assets/templates/voestalpine/voestalpine-light.ttf') format('truetype');
}

@font-face {
  font-family: 'voestalpine-regular';
  src: url('../assets/templates/voestalpine/voestalpine-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'voestalpine-bold';
  src: url('../assets/templates/voestalpine/voestalpine-bold.ttf') format('truetype');
}

.ant-input {
  border-color: black;
}

.slider-carnot .slick-next::before {
  color: #C7D51F
}

.slider-carnot .slick-prev::before {
  color: #C7D51F
}

.slider-procaps .slick-next::before {
  color: #D81C09
}

.slider-procaps .slick-prev::before {
  color: #D81C09
}

.slider-ollamani .slick-next::before {
  color: #394E9F
}

.slider-ollamani .slick-prev::before {
  color: #394E9F
}

.slide-config .slick-slide {
  margin: 0 10px; /* Espacio entre slides */
}

.track-config .slick-track {
  display: flex;
  align-items: center;
}


.table-ant .ant-table-thead>tr>th {
  background-color: #3E3CDF !important;
  color: white !important;
  text-align: center;
}

.table-ant .ant-table-tbody>tr>td {
  text-align: center;
}

/* el color del boton por defecto es #3E3CDF de ant design */

/* .ant-btn.ant-btn-default {
  border-color: #3E3CDF;
  color: #3E3CDF;
}

.ant-btn.ant-btn-default:hover {
  background: #3E3CDF !important;
  color: white !important;
} */